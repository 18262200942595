<template>
  <div class="layer_01">
    <div class="layer_top">
      <h2 class="layer_top_title">축제 {{ id ? "수정" : "등록" }}</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_01_box">
      <form>
        <table class="layer_01_table">
          <colgroup>
            <col style="width: 107px" />
            <col style="width: 535px" />
            <col style="width: 107px" />
            <col style="width: 505px" />
          </colgroup>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">축제명</td>
            <td colspan="3" class="pb_15">
              <input type="text" class="text_box_07" v-model="title" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">축제 일시</td>
            <td class="pb_15">
              <date-picker v-model="startDt" :popover="{ visibility: 'click' }">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
              <span class="ml_04 mr_04">~</span>
              <date-picker v-model="endDt" :popover="{ visibility: 'click' }">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
            </td>
            <td class="layer_tbl_title pb_15">지역</td>
            <td class="pb_15">
              <div class="area_select_box_02">
                <base-select
                  :options="areas"
                  v-model="region"
                  placeholder="지역 선택"
                />
              </div>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15" ref="refTd">주소</td>
            <td class="pb_15" colspan="3">
              <input
                type="text"
                class="address_box"
                ref="refAddressInput"
                readonly
                v-model="address"
              />
              <base-button type="upload" @click="searchAddress"
                >주소찾기</base-button
              >
              <div style="width: 826px; height: auto" ref="refMap"></div>
            </td>
          </tr>
          <!-- <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">태그</td>
            <td class="pb_15" colspan="3">
              <div class="input_area_03">
                <input
                  type="text"
                  class="input_box_03 pl_12"
                  v-model="tag"
                  placeholder=", 로 구분"
                />
              </div>
            </td>
          </tr> -->
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">홈페이지</td>
            <td class="pb_15" colspan="3">
              <div class="input_area_03">
                <input type="text" class="input_box_03 pl_12" v-model="url" />
              </div>
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">내용</td>
            <td colspan="3" class="pb_15">
              <textarea
                class="textarea_style_03"
                style="resize: none; white-space: pre"
                v-model="text"
              ></textarea>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">커버 이미지</td>
            <td class="pb_15" colspan="3">
              <input
                type="text"
                class="text_box_02 mr_30"
                v-model="cover.name"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="cover_upload"
                ref="refCover"
                @change="previewCover"
              />
              <label for="cover_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectCover || cover.name"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ cover.name }}</span>
                <div class="cancel_img" @click.prevent.stop="removeCover">
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="big_box_title">사진</td>
            <td colspan="3">
              <div
                style="margin-bottom: 15px"
                v-for="(file, index) in files.items"
                :key="`file_${index}`"
                class="file_row"
              >
                <input
                  type="text"
                  class="text_box_02 mr_30"
                  v-model="file.name"
                  readonly
                />
                <input
                  style="display: none"
                  type="file"
                  accept=".png, .jpg, .jpeg, .bmp, .gif"
                  :id="`file_upload${index}`"
                  :ref="`file_${index}`"
                  @change="previewFile(`file_${index}`, file)"
                />
                <label :for="`file_upload${index}`" class="file_btn">
                  찾아보기
                </label>
                <div
                  v-if="file.src || file.name"
                  class="img_name_box stamp_img_n_01"
                >
                  <span class="img_name">{{ file.name }}</span>
                  <div
                    class="cancel_img"
                    @click.prevent.stop="removeFile(`file_${index}`, file)"
                  >
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="edit">{{
            id ? "수정" : "등록"
          }}</base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseSelect from "@/components/core/BaseSelect.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {
    BaseSelect,
    BaseButton,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      validate: false,
      title: "",
      text: "",
      tag: "",
      url: "",
      updatefile: [],
      address: "",
      startDt: this.$date().format("YYYY-MM-DD"),
      endDt: this.$date().add(1, "month").format("YYYY-MM-DD"),
      region: "",
      files: {
        items: [],
        limit: 5,
        templete: {
          url: "",
          src: null,
          name: "",
        },
      },
      cover: {
        name: "",
        url: "",
      },
      selectCover: null,
      areas: [
        { name: "전체", value: "전체" },
        { name: "서울특별시", value: "서울특별시" },
        { name: "경기도", value: "경기도" },
        { name: "강원도", value: "강원도" },
        { name: "인천광역시", value: "인천광역시" },
        { name: "광주광역시", value: "광주광역시" },
        { name: "대구광역시", value: "대구광역시" },
        { name: "대전광역시", value: "대전광역시" },
        { name: "울산광역시", value: "울산광역시" },
        { name: "부산광역시", value: "부산광역시" },
        { name: "세종시", value: "세종시" },
        { name: "제주도", value: "제주도" },
        { name: "세종시", value: "세종시" },
        { name: "충청북도", value: "충청북도" },
        { name: "충청남도", value: "충청남도" },
        { name: "전라북도", value: "전라북도" },
        { name: "전라남도", value: "전라남도" },
        { name: "경상북도", value: "경상북도" },
        { name: "경상남도", value: "경상남도" },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (this.id) {
        await client.festivalFindone(this.id).then((response) => {
          const { data } = response;
          this.title = data.title;
          this.text = data.text;
          this.tag = data.tag;
          this.url = data.url;
          this.file = data.file;
          this.updatefile = this.file;
          this.updatefile.forEach((file) => {
            this.files.items.push({
              url: file.url,
              name: file.name,
              src: null,
            });
          });
          this.cover.url = data.cover.name;
          this.cover.name = data.cover.name;
          this.address = data.address;
          this.startDt = this.$date(data.startDate).format("YYYY-MM-DD");
          this.endDt = this.$date(data.endDate).format("YYYY-MM-DD");
          this.region = data.region;
        });
      }
      this.getFileData();
    },
    getFileData() {
      let fileRow = true;
      while (fileRow) {
        if (this.files.items.length < this.files.limit) {
          this.files.items.push(Object.assign({}, this.files.templete));
        } else {
          fileRow = false;
        }
      }
    },
    searchAddress() {
      let mapContainer = this.$refs.refMap, // 지도를 표시할 div
        mapOption = {
          center: new window.daum.maps.LatLng(37.537187, 127.005476), // 지도의 중심좌표
          level: 5, // 지도의 확대 레벨
        },
        refTd = this.$refs.refTd,
        refAddressInput = this.$refs.refAddressInput;

      new window.daum.Postcode({
        oncomplete: (data) => {
          //주소-좌표 변환 객체를 생성
          let geocoder = new window.daum.maps.services.Geocoder();
          this.address = data.address; // 주소 정보를 해당 필드에 넣는다.
          this.address +=
            data.buildingName !== "" || data.apartment == "Y"
              ? " " + data.buildingName
              : "";
          refAddressInput.focus();
          // 주소로 상세 정보를 검색
          geocoder.addressSearch(data.address, function (results, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === window.daum.maps.services.Status.OK) {
              var result = results[0]; //첫번째 결과의 값을 활용
              //지도를 미리 생성
              let map = new window.daum.maps.Map(mapContainer, mapOption);
              //마커를 미리 생성
              let marker = new window.daum.maps.Marker({
                position: new window.daum.maps.LatLng(37.537187, 127.005476),
                map: map,
              });
              // 해당 주소에 대한 좌표를 받아서
              var coords = new window.daum.maps.LatLng(result.y, result.x);
              // 지도를 보여준다.
              mapContainer.style.display = "block";
              mapContainer.style.height = "300px";
              mapContainer.style.marginTop = "15px";
              refTd.style.verticalAlign = "top";
              refTd.style.paddingTop = "12px";

              map.relayout();
              // 지도 중심을 변경한다.
              map.setCenter(coords);
              // 마커를 결과값으로 받은 위치로 옮긴다.
              marker.setPosition(coords);
            }
          });
        },
      }).open();
    },
    async edit() {
      if (this.url) {
        if (!this.isValidUrl(this.url)) {
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "유효하지 않은 url 입니다",
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          this.validate = false;
        } else {
          this.validate = true;
        }
      } else {
        this.validate = true;
      }
      let url = [];
      for await (const file of this.files.items) {
        if (file.src) {
          let form = new FormData();
          form.append("uploadFile", file.src);
          await client.fileAdd(form).then(
            (response) => {
              file.url = response.data.name;
              url.push({ url: file.url });
            },
            (error) => {
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
            }
          );
        } else if (file.name) {
          url.push({ url: file.name });
        }
      }

      if (this.selectCover) {
        let form = new FormData();
        form.append("uploadFile", this.selectCover);
        await client.fileAdd(form).then(
          (response) => {
            const { data } = response;
            this.cover.url = data.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
      /*
      if (0 < this.files.items.length) {
        await this.files.items.forEach((file) => {
          if (file.src) {
            let form = new FormData();
            form.append("uploadFile", file.src);
            console.log(file.src);
            await client.fileAdd(form).then(
              (response) => {
                console.log("fileAdd response");
                file.url = response.data.name;
                url.push({ url: file.url });
                console.log(url.length);
              },
              (error) => {
                console.log("fileAdd error");
                console.log(error);
              }
            );
          }
        });
      }
      */
      let params = {
        title: this.title,
        address: this.address,
        tag: this.tag,
        url: this.url,
        file: url,
        cover: this.cover.url,
        text: this.text,
        startDate: this.startDt,
        endDate: this.endDt,
        region: this.region,
      };
      if (!this.region) {
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "지역을 선택해주세요",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        this.validate = false;
      } else {
        if (this.validate) {
          if (this.id) {
            await client.festivalModify(this.id, params).then(
              () => {
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: "축제가 정상적으로 수정되었습니다.",
                  },
                  {
                    width: 400,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
                this.$emit("close", { refresh: true });
              },
              (error) => {
                const { data } = error.response;
                let message = data.message;
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: message,
                  },
                  {
                    adaptive: true,
                    width: "90%",
                    maxWidth: 600,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
              }
            );
          } else {
            await client.festivalAdd(params).then(
              () => {
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: "축제가 정상적으로 등록되었습니다.",
                  },
                  {
                    width: 400,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
                this.$emit("close", { refresh: true });
              },
              (error) => {
                const { data } = error.response;
                let message = data.message;
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: message,
                  },
                  {
                    adaptive: true,
                    width: "90%",
                    maxWidth: 600,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
              }
            );
          }
        }
      }
    },
    removeFile(ref, file) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (file) {
                file.src = null;
                file.name = "";
                file.url = "";
              }
            }
          },
        }
      );
    },
    removeCover() {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectCover) {
                // 로컬에서 선택한 파일이 있을경우
                this.selectCover = null;
              }
              this.cover.name = "";
              this.cover.url = "";
            }
          },
        }
      );
    },
    previewFile(ref, file) {
      let selectFile = this.$refs[ref][0];
      if (0 < selectFile.files.length) {
        file.src = selectFile.files[0];
        file.name = selectFile.files[0].name;
      } else {
        file.src = null;
      }
    },
    previewCover() {
      let selectFile = this.$refs.refCover;
      if (0 < selectFile.files.length) {
        this.selectCover = selectFile.files[0];
        let fileExt = this.selectCover.name.substring(
          this.selectCover.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectCover.size <= 1048576
        ) {
          this.cover.name = this.selectCover.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectCover = null;
        }
      } else {
        this.selectCover = null;
      }
    },

    // url 유효성 검사
    isValidUrl(url) {
      let regUrl =
        /(http|https):\/\/((\w+)[.])+(asia|biz|cc|cn|com|de|eu|in|info|jobs|jp|kr|mobi|mx|name|net|nz|org|travel|tv|tw|uk|us)(\/(\w*))*$/i;
      return regUrl.test(url);
    },
  },
};
</script>
