<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">축제</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 120px" />
            <col style="width: 369px" />
            <col style="width: 120px" />
            <col style="width: 369px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">축제명</td>
            <td class="box_style_02">
              {{ title }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">축제 기간</td>
            <td class="box_style_02">
              {{ `${startDate} ~ ${endDate}` }}
            </td>
            <td class="box_style_02 list_title_style">지역</td>
            <td class="box_style_02" colspan="3">
              {{ region }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">주소</td>
            <td class="box_style_02" colspan="3">
              {{ address }}
            </td>
          </tr>
          <!-- <tr class="line_style">
            <td class="box_style_02 list_title_style">태그</td>
            <td class="box_style_02" colspan="3">
              {{ tag }}
            </td>
          </tr> -->
          <tr class="line_style">
            <td class="box_style_02 list_title_style">홈페이지</td>
            <td class="box_style_02" colspan="3">
              {{ url }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg">내용</td>
            <td class="box_style_03" colspan="3">
              {{ text }}
            </td>
          </tr>
          <tr class="line_style">
            <td
              class="box_style_02 list_title_style_lg"
              v-if="files.length != 0"
            >
              사진
            </td>
            <td class="box_style_03" v-if="files.length != 0">
              <img
                v-for="(file, index) in files"
                :key="`file_${index}`"
                :src="`${file.url}${file.path}`"
                alt="배너 이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: 100%;
                  max-height: 100px;
                  display: block;
                  margin-bottom: 10px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style_lg" v-if="cover">
              커버 이미지
            </td>
            <td class="box_style_03" style="vertical-align: top" v-if="cover">
              <img
                :src="`${cover.url}${cover.path}`"
                alt="커버 이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: 100%;
                  max-height: 100px;
                "
              />
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    type: {
      type: String,
      default: () => "",
    },
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      title: "",
      text: "",
      tag: "",
      url: "https://",
      updatefile: [],
      address: "",
      startDate: "",
      endDate: "",
      region: "",
      files: {
        items: [],
        limit: 5,
        templete: {
          name: "",
        },
      },
      cover: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.festivalFindone(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.title = data.title;
          this.text = data.text;
          this.tag = data.tag;
          this.url = data.url;
          this.files = data.file;
          console.log(this.file);
          // this.updatefile = this.file;
          // this.updatefile.forEach((file) => {
          //   this.files.items.push({
          //     name: file.name,
          //   });
          // });
          this.cover = data.cover;
          this.address = data.address;
          this.startDate = data.startDate;
          this.endDate = data.endDate;
          this.region = data.region;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
